import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Notification0 } from '../../../components/images/support/users/Notification0.generated';
import { Notification1 } from '../../../components/images/support/users/Notification1.generated';
import { Notification2 } from '../../../components/images/support/users/Notification2.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "user-notifications-️"
    }}>{`User Notifications ✉️`}</h1>
    <p>{`This guide shows how to update user notification preferences.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Click the notification button in the top right corner.`}</li>
    </ol>

    <Notification0 mdxType="Notification0" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Settings`}</strong>{` button.`}</li>
    </ol>

    <Notification1 mdxType="Notification1" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Adjust the notification settings to your preference.`}</li>
    </ol>

    <Notification2 mdxType="Notification2" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      